<template>
  <div>
    <p class="transcript--compliance--question">
      The expert agrees and assents to the fact that they read and understood material non-public
      (&ldquo;MNPI&rdquo;) and confidential information as defined in the Tegus Compliance Training Materials
      and will not disperse such information during the consultation.
    </p>

    <p class="transcript--compliance--answer">
      Yes
    </p>

    <p class="transcript--compliance--question">
      The expert agrees and assents that they will not disclose any information that they have an obligation
      by agreement or otherwise, to treat as confidential, including MNPI, trade secrets,
      proprietary or other confidential information during the Consultation.
    </p>

    <p class="transcript--compliance--answer">
      Yes
    </p>

    <p class="transcript--compliance--question">
      The expert agrees and assents that this Consultation will be recorded and transcribed for Tegus
      and its Clients.
    </p>

    <p class="transcript--compliance--answer">
      Yes
    </p>
  </div>
</template>

<script lang="ts">
export default {
  name: 'TranscriptCompliance'
}
</script>

<script setup lang="ts">

</script>

<style lang="scss" scoped>
.transcript--compliance--question {
  @include k2-font-text-text;

  margin-bottom: 4px;
}

.transcript--compliance--answer {
  @include k2-font-text-text-bold;

  margin-bottom: 16px;
}
</style>
